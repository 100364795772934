@import '../_variables';

.cr-pagination-action() {
  width: auto;
  min-width: 2.5rem;
  position: relative;
  padding: 0 0.75rem;

  &:before {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    background-color: @color-default-5;
    mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M46.38 36.69L33.66 24a2.33 2.33 0 0 0-3.31 0L17.62 36.7a2.4 2.4 0 0 0-.69 1.66 2.34 2.34 0 0 0 .69 1.64 2.4 2.4 0 0 0 3.32 0L32 29l11.06 11a2.35 2.35 0 0 0 3.32-3.32z'/></svg>");
    top: 50%;
    @media (@max-screen-xs) {
      display: none;
    }
  }

  &:hover:before {
    background-color: @color-primary-3;
  }
}

ul.cr-pagination {
  font-size: 1rem;
  padding: 1rem;
  list-style: none;
  display: flex;
  justify-content: center;
  font-family: @font-primary;

  @media (@min-screen-sm) and (@max-screen-lg) {
    justify-content: left;
  }

  > .cr-pagination-skip {
    margin: auto 0.3rem 0.5rem;

    &:before {
      content: '...';
    }
  }

  > .cr-pagination-input {
    position: relative;
    margin-right: 0.5rem;
    width: 7rem;

    input {
      height: 100%;
      line-height: 2.5rem;
      background: none;
      text-align: center;
      margin: 0 0.5rem 0 0;
      padding: 0;
      border: 2px solid @color-default-2;
      border-radius: 1.25rem;
      width: 3rem !important;
      -webkit-appearance: none;
    }

    span.cr-pagination-total {
      position: absolute;
      right: 0;
      width: 4rem;
      top: 0;
      line-height: 2.5rem;
      text-align: center;
      height: 2.5rem;
    }
  }

  > li:not(.cr-pagination-skip):not(.cr-pagination-input) {
    font-family: @roboto;
    text-align: center;
    min-width: calc(2.25rem + 4px);
    height: calc(2.25rem + 4px) !important; // line-height + 2 * border
    line-height: 2.25rem;
    cursor: pointer;
    user-select: none;
    transition: border-color @transition-duration @transition-curve,
    background-color @transition-duration @transition-curve,
    color @transition-duration @transition-curve;
    margin-right: 0.5rem;
    padding: 0 0.5rem;

    a {
      color: @color-default-5;
      line-height: 2.5rem;
    }

    &:active,
    &.cr-pagination-current {
      border-color: @color-white;
      background-color: @color-white;
    }

    &:hover {
      border-color: @color-primary-3;
      color: @color-primary-3;

      a {
        color: @color-primary-3;
        text-decoration: none;
      }
    }

    &:active,
    &.cr-pagination-current {
      color: @color-black;
      font-weight: 700;

      a {
        color: @color-black;
        font-weight: 700;
      }
    }

    &.cr-pagination-previous {
      border: 1px solid @color-default-3;
      .cr-pagination-action();
      padding: 0 2rem;

      &:before {
        transform: translateY(-50%) rotate(270deg);
        left: 1rem;
      }

      @media (@max-screen-xs) {
        padding: 0 0.5rem;
        background-color: @color-black;
        a {
          color: @color-white;
        }
      }
    }

    &.cr-pagination-next {
      border: 1px solid @color-default-3;
      .cr-pagination-action();
      padding: 0 2rem;

      &:before {
        transform: translateY(-50%) rotate(90deg);
        right: 1rem;
      }

      @media (@max-screen-xs) {
        padding: 0 0.5rem;
        background-color: @color-black;
        a {
          color: @color-white;
        }
      }
    }
  }


  .cr-pagination-disabled {
    border-color: @color-default-1 !important;
    pointer-events: none !important;

    a {
      color: @color-default-3 !important;
    }
  }

  .cr-per-page {
    border: 1px solid @color-default-3;
    height: calc(2.25rem + 2px) !important;
    padding: 0 1.5rem !important;

    &.selected {
      border-width: 2px;
    }
  }

  @media (@max-screen-xs) {
    padding: 1rem 0;
  }
}

.products-per-page {
  //@media(min-)
  @media (max-width: 880px) {
    position: relative !important;
    margin-top: 3rem;
    display: flex !important;
    justify-content: center;

  }
}